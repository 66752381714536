import React from "react";
import { RDCCustomerReviewCard, RDCCustomerReviewCardProps } from "./RDCCustomerReviewCard";

/**
 * Props to pass to a `RDCCustomerReviewsCarousel` component.
 */
interface RDCCustomerReviewsCarouselProps {
  reviews: RDCCustomerReviewCardProps[];
}

/**
 * Carousel component that displays customer reviews.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCCustomerReviewsCarousel: React.FC<RDCCustomerReviewsCarouselProps> = (props) => {
  const { reviews } = props;

  return (
    <div className="daisy-carousel daisy-carousel-center w-full">
      {reviews.map((props, i) => (
        <div id={`rating${i}`} className="daisy-carousel-item mx-4">
          <RDCCustomerReviewCard {...props} />
        </div>
      ))}
    </div>
  );
};
