import styled from "styled-components";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const ThankYou = () => {
  const ZipCodeSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #e6f7ff;
    padding: 50px 50px 80px 50px;
    text-align: center;
    width: 100vw;
  `;

  const Container = styled.section`
    width: 400px;
  `;

  const Title = styled.p`
    font-size: 32px;
    font-weight: bold;
    color: #111d57;
    margin-bottom: 40px;
    line-height: 1.5;
  `;

  const Subtitle = styled.p`
    color: #666;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 1.5;
  `;

  return (
    <ZipCodeSection>
      <GlobalStyles /> {/* Include global styles */}
      <Container>
        <Title>Thank you for your interest!</Title>
        <Subtitle>
          We will notify you when we begin service in your area
        </Subtitle>
      </Container>
    </ZipCodeSection>
  );
};

export default ThankYou;
