import { useState } from "react";
import {
  RDCCheckAvailabilityHero,
  RDCCustomerReviewCardProps,
  RDCCustomerReviewsCarousel,
  RDCNavbar,
  RDCAlertBanner,
} from "../components";
import HeroImage from "../images/content/HeroImage.png";

// TODO: pull these reviews from a CMS
const REVIEWS: RDCCustomerReviewCardProps[] = [];
for (let i = 5; i > 0; i -= 0.5) {
  REVIEWS.push({
    name: "First Last",
    imgUrl: HeroImage,
    numStars: i,
    textContent: (
      <span>
        <p>
          This is some sample review text that will say really awesome things about EcoTrove and the product that we are
          building.
        </p>
        <br />
        <p>It can have multiple lines!</p>
      </span>
    ),
  });
}

/**
 * Landing page that should be displayed to the user upon arrival.
 * @returns a React `FunctionComponent`.
 */
export const RDCLandingPage: React.FC = () => {
  const [errorAlert, setErrorAlert] = useState<string>("");

  return (
    <div className="tw-preflight grid grid-cols-1">
      {/* Error banner that can be set by subcomponents */}
      {errorAlert ? (
        <RDCAlertBanner type="error" message={errorAlert} onClickClose={() => setErrorAlert("")} />
      ) : undefined}

      {/* First viewheight */}
      <div className="flex flex-col h-dvh">
        {/* Navbar */}
        <span className="flex-none">
          <RDCNavbar />
        </span>
        {/* Check availability hero */}
        <span className="grow mt-12 lg:mt-20 mx-content-mobile lg:mx-content mb-default">
          <RDCCheckAvailabilityHero setErrorAlert={setErrorAlert} />
        </span>
        <div className="bg-secondary h-5" />
      </div>

      {/* Second viewheight */}
      <div className="flex flex-col h-dvh">
        {/* Customer reviews */}
        <span className="flex-none bg-gradient-to-b from-secondary py-4 lg:py-8">
          <p className="flex justify-center text-sm text-neutral-content mb-8">
            See why our customers rate us excellent
          </p>
          <RDCCustomerReviewsCarousel reviews={REVIEWS} />
        </span>
        {/* Temporary placeholder to show space below carousel */}
        <div className="grow"></div>
      </div>
    </div>
  );
};
