import EcoTroveLogo from "../images/logos/EcoTroveLogo.svg";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import {
  ALL_BLOGS_ROUTE,
  GET_QUOTE_ROUTE,
  HOW_IT_WORKS_ROUTE,
  LOGIN_PAGE_ROUTE,
  OUR_TEAM_ROUTE,
  REDESIGN_ROUTE,
} from "../../constants";
import { MouseEventHandler, useState } from "react";

type MenuLink = { label: string; route: string };

// Links that are shown in the center of the navbar
const MENU_LINKS: MenuLink[] = [
  { label: "How It Works", route: HOW_IT_WORKS_ROUTE },
  { label: "Blog", route: ALL_BLOGS_ROUTE },
  { label: "About Us", route: OUR_TEAM_ROUTE },
];

// Action buttons that are shown at the end of the navbar
const ACTION_BUTTONS: MenuLink[] = [
  { label: "Get Started", route: GET_QUOTE_ROUTE },
  { label: "Login", route: LOGIN_PAGE_ROUTE },
];

/**
 * Helper method that creates an array of link elements based on the provided screen size.
 * @param props the props to get the menu items with
 * @returns an array of menu links.
 */
const getMenuLinks = (props: { isSmallScreen: boolean }): JSX.Element[] => {
  const links = MENU_LINKS.map(({ label, route }) => (
    <li key={label}>
      <a href={route}>{label}</a>
    </li>
  ));
  // Add a login link to smaller screens
  if (props.isSmallScreen) {
    const login = ACTION_BUTTONS[1];
    links.push(
      <li className="bg-secondary rounded-full" key={login.label}>
        <a href={login.route}>{login.label}</a>
      </li>
    );
  }
  return links;
};

/**
 * Navigation bar component that should be rendered on every page.
 * @returns a React `FunctionComponent`.
 */
export const RDCNavbar: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  /**
   * Handler for dropdown menu click events.
   * @param e the mouse event
   */
  const handleDropdownClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setDropdownOpen((prev) => !prev);
  };

  return (
    <header className="flex px-4 lg:px-8 py-3">
      {/* Logo */}
      <a className="flex-none flex gap-default text-lg items-center px-1 lg:px-6" href={REDESIGN_ROUTE}>
        <img src={EcoTroveLogo} height={28} width={28} alt="EcoTrove Logo" />
        <p className="text-lg">EcoTrove</p>
      </a>
      {/* Center menu (if larger screen) */}
      <div className="grow flex justify-center">
        <ul className="daisy-menu daisy-menu-md daisy-menu-horizontal hidden lg:flex">
          {getMenuLinks({ isSmallScreen: false })}
        </ul>
      </div>
      {/* Action buttons */}
      <div className="flex-none flex gap-default">
        <a className="daisy-btn daisy-btn-primary text-md" href={ACTION_BUTTONS[0].route}>
          {ACTION_BUTTONS[0].label}
        </a>
        <a className="daisy-btn daisy-btn-secondary hidden lg:flex mr-default" href={ACTION_BUTTONS[1].route}>
          <span className="text-secondary-content">{ACTION_BUTTONS[1].label}</span>
        </a>
        {/* Dropdown menu (if smaller screen) */}
        <details className="daisy-dropdown daisy-dropdown-end" open={dropdownOpen}>
          <summary
            className="daisy-btn daisy-btn-ghost lg:hidden"
            aria-label="open dropdown menu"
            onClick={handleDropdownClick}>
            {dropdownOpen ? <XMarkIcon className="hero-icon" /> : <Bars3Icon className="hero-icon" />}
          </summary>
          <ul className="daisy-dropdown-content daisy-menu daisy-menu-md bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
            {getMenuLinks({ isSmallScreen: true })}
          </ul>
        </details>
      </div>
    </header>
  );
};
