import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials";
import BottomLandingSectionGetQuote from "../components/BottomLandingSectionGetQuote";
import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';
import { Helmet } from 'react-helmet';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
`;

const HowItWorksPage = () => {
  return (
    <>
    <Helmet>
      <title>EcoTrove | FAQs</title>
      <meta name="description" content="Learn how EcoTrove finds up to 10% savings on your utility account, how EcoTrove operates and how you easily manage your subscription." />
      <meta name="keywords" content="utility account, PG&E account, get a personalized quote, replace your utility bills, save on your utility bills, clean energy, utility bill savings" />
    </Helmet> 

    <main>
    <PageContainer>
      <Navbar />
        <PlasmicRootProvider loader={PLASMIC}>
            <PlasmicComponent component="FAQ" />
        </PlasmicRootProvider>
      <Testimonials />
      <BottomLandingSectionGetQuote />
      <Footer></Footer>
    </PageContainer>
    </main>
  </>
  );
};

export default HowItWorksPage;
