import { useState } from "react";
import styled from "styled-components";
import { supabase } from "../supabaseClient";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

interface EnterZipCodeProps {
  advanceStage: (nextStage: string) => void;
}

const EmailSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e6f7ff;
  padding: 50px 50px 80px 50px;
  text-align: center;
  width: 100vw;
`;

const Container = styled.section`
  width: 400px;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: #111d57;
  margin-bottom: 40px;
  line-height: 1.5;
`;

const Subtitle = styled.h2`
  color: #666;
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.5;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const EmailForm = styled.form`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 40px;
`;

const EmailInput = styled.input`
  padding: 10px;
  border: 2px solid #111d57;
  border-radius: 5px;
  font-size: 22px;
  background-color: #e6f7ff;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const EnterButton = styled.button`
  padding: 10px 20px;
  background-color: #284389;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: bold;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #111d57;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 18px;
`;

const EnterEmail: React.FC<EnterZipCodeProps> = ({ advanceStage }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError("");
  };

  const validateEmail = async (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    if (!emailRegex.test(email)) {
      return false;
    }

    const domain = email.split('@')[1];
    try {
      const lookup = await fetch(`https://dns.google/resolve?name=${domain}&type=MX`);
      const result = await lookup.json();
      return result.Answer !== undefined;
    } catch (e) {
      console.error("DNS lookup failed:", e);
      return false;
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!(await validateEmail(email))) {
      setError("Please enter a valid email address.");
    } else {
      setError("");
      addEmail(email);
      advanceStage("waitlistThankYou");
    }
  };

  const addEmail = async (email: string) => {
    const recordId = localStorage.getItem("record_id");

    if (recordId) {
      const { error } = await supabase
        .from("interest-users")
        .update({ email: email })
        .eq("id", parseInt(recordId))
        .select();

      if (error) {
        console.error("Error updating data: ", error);
      }
    } else {
      console.error("No zipcode found for user");
    }
  };

  return (
    <EmailSection>
      <GlobalStyles /> {/* Include global styles */}
      <Container>
        <Title>We will start serving your area soon</Title>
        <EmailForm onSubmit={handleSubmit}>
          <EmailInput
            type="email"
            placeholder="Your email..."
            value={email}
            onChange={handleInputChange}
            aria-describedby="emailError"
          />
          <EnterButton type="submit">Enter</EnterButton>
        </EmailForm>
        {error && <ErrorText id="emailError">{error}</ErrorText>}
        <Subtitle>
          Please enter your email and we will notify you as soon as service is
          available.
        </Subtitle>
      </Container>
    </EmailSection>
  );
};

export default EnterEmail;
