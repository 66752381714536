import { SubmitHandler, useForm } from "react-hook-form";
import { supabase } from "../../supabaseClient";
import mixpanel from "mixpanel-browser";
import { RDCZipCodeInput } from "./RDCZipCodeInput";
import HeroImage from "../images/content/HeroImage.png";
import PGELogo from "../images/logos/PGELogo.svg";
import SCELogo from "../images/logos/SCELogo.svg";
import SDGELogo from "../images/logos/SDGELogo.svg";
import DataGuardLogo from "../images/logos/DataGuardLogo.svg";
import TrustPilotLogo from "../images/logos/TrustPilotLogo.svg";
import { GET_QUOTE_ROUTE, UTILITIES_SERVED } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

interface CheckAvailabilityHeroFieldValues {
  zipCode: string;
}

/**
 * Props to render the `RDCCheckAvailabilityHero` component with.
 */
export interface RDCCheckAvailabilityHeroProps {
  setErrorAlert: (errorBanner: string) => void;
}

/**
 * Component that allows a user to to check EcoTrove availability based on ZIP code.
 * @returns a React `FunctionComponent`.
 */
export const RDCCheckAvailabilityHero: React.FC<RDCCheckAvailabilityHeroProps> = (props) => {
  const { setErrorAlert } = props;
  const [validationError, setValidationError] = useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CheckAvailabilityHeroFieldValues>();

  /**
   * Handler for the "Check Availability" submit button.
   * @param event the submit event
   * @returns void
   */
  const handleCheckAvailabilityHero: SubmitHandler<CheckAvailabilityHeroFieldValues> = async (event): Promise<void> => {
    try {
      const { zipCode } = event;
      const { data: interestUserData } = await supabase
        .from("interest-users")
        .insert([{ zipcode: zipCode }])
        .select();
      // Set record_id in local storage
      if (interestUserData && interestUserData.length > 0) {
        const recordId = interestUserData[0].id;
        localStorage.setItem("record_id", recordId.toString());
      }
      // Check if ZIP code is currently served
      const { data: zipCodeData, error: zipError } = await supabase
        .from("pge_zipcodes_new")
        .select()
        .eq("zipcode", zipCode)
        .in("utility_name", UTILITIES_SERVED);
      // If an error was returned, throw it. This will be handled and displayed as an error message below
      if (zipError) {
        throw new Error(zipError?.message);
      }
      // Navigate to get quote page
      if (zipCodeData && zipCodeData.length > 0) {
        mixpanel.track("Check Zipcode for Savings clicked");
        navigate(`${GET_QUOTE_ROUTE}?initialStage=${encodeURIComponent("connectPGE")}`);
        window.scrollTo(0, 0);
      } else {
        navigate(`${GET_QUOTE_ROUTE}?initialStage=${encodeURIComponent("waitlistEmail")}`);
      }
    } catch (error) {
      // Catch errors and display an error alert
      setErrorAlert("An error occurred while checking ZIP code. Please try again later.");
      window.scrollTo(0, 0);
      return;
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2 h-full">
        {/* Left column */}
        <div className="col-span-1 flex flex-col">
          {/* Text blurb */}
          <div className="flex-none">
            <div className="grid grid-cols-1 gap-5 justify-items-center lg:justify-items-start">
              <p className="daisy-badge daisy-badge-neutral daisy-badge-lg">
                Up to $400 cheaper. Same fixed price every month.
              </p>
              <h1>Lower your utility bills.</h1>
              <span className="text-neutral-content leading-relaxed">
                <p>
                  EcoTrove is your energy concierge. We secure the cheapest electricity and gas rates in your area and
                  replace your utility billing with a fixed-price payment plan.
                </p>
                <br />
                <p>Save up to $400 a year while using 30% more green energy, helping your wallet and the planet.</p>
              </span>
            </div>
          </div>
          {/* ZIP code input */}
          <div className="grow mt-8 lg:mt-14">
            <form className="grid justify-center lg:justify-start" onSubmit={handleSubmit(handleCheckAvailabilityHero)}>
              <div className="flex gap-default">
                <RDCZipCodeInput register={register} errors={errors} setValidationError={setValidationError} />
                <button className="daisy-btn daisy-btn-primary" type="submit">
                  Check Availability
                </button>
              </div>
              {validationError ? (
                <p className="daisy-label daisy-label-text text-error" role="alert">
                  {validationError}
                </p>
              ) : (
                // Add some extra spacing so the screen doesn't jump around when a validation error appears/disappears
                <p className="mb-9"></p>
              )}
            </form>
          </div>
          {/* Trusted by icons */}
        </div>
        {/* Right Column */}
        <div className="col-span-1 hidden lg:grid lg:justify-end lg:align-top">
          <img src={HeroImage} alt="two kids reading" />
        </div>
      </div>
      <div className="flex-none">
        <div className="grid justify-items-center lg:justify-items-start text-neutral-500">
          <p className="text-sm">Trusted by</p>
          <div className="flex flex-wrap justify-evenly lg:justify-start justify-items-center mt-3 gap-6 lg:gap-10">
            <img src={PGELogo} alt="Pacific Gas and Electric logo" />
            <img src={SCELogo} alt="Southern California Edison logo" />
            <img src={SDGELogo} alt="San Diego Gas and Electric logo" />
            <img src={DataGuardLogo} alt="DataGuard logo" />
            <img src={TrustPilotLogo} alt="TrustPilot logo" />
          </div>
        </div>
      </div>
    </div>
  );
};
