import { Dispatch, SetStateAction, useEffect } from "react";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";

const ZIP_CODE_FIELD_NAME = "zipCode";

const ZIP_CODE_VALIDATION = {
  required: true,
  minLength: 5,
  maxLength: 5,
  pattern: /^[0-9]*$/,
};

// Using the keys from `ZIP_CODE_VALIDATION` as the type here guarantees that `ERROR_MESSAGES`
// will always contain a corresponding error message for each validation type.
type ValidationType = keyof typeof ZIP_CODE_VALIDATION;
const ERROR_MESSAGES: Record<ValidationType, string> = {
  required: "ZIP code is required",
  minLength: "ZIP code must be 5 characters",
  maxLength: "ZIP code must be 5 characters",
  pattern: "ZIP code must only contain numbers",
};

/**
 * Props to render a `RDCZipCodeInput` component with.
 * NOTE: this component must be used with a `react-hook-form` input form.
 */
export interface RDCZipCodeInputProps {
  register: UseFormRegister<FieldValues & { [ZIP_CODE_FIELD_NAME]: string }>;
  errors: FieldErrors<FieldValues & { [ZIP_CODE_FIELD_NAME]: string }>;
  setValidationError: Dispatch<SetStateAction<string>>;
}

/**
 * Form input component that validates a ZIP code.
 * @param props the props to render the component with.
 * @returns a React `FunctionComponent`.
 */
export const RDCZipCodeInput: React.FC<RDCZipCodeInputProps> = (props) => {
  const { register, errors, setValidationError } = props;

  // This effect is always rerun to update the validation error
  useEffect(() => {
    const currentErrorKeys = Object.keys(ERROR_MESSAGES).filter((key) => errors[ZIP_CODE_FIELD_NAME]?.type === key);
    // Safe cast: currentErrors should always be one of the `ValidationType`s we specified above
    setValidationError(ERROR_MESSAGES[currentErrorKeys[0] as ValidationType]);
  });

  return (
    <div className="grid grid-cols-1 gap-2">
      <input
        className="daisy-input daisy-input-bordered max-w-xs aria-invalid:daisy-input-error aria-invalid:text-error"
        {...register(ZIP_CODE_FIELD_NAME, ZIP_CODE_VALIDATION)}
        type="text"
        aria-invalid={errors[ZIP_CODE_FIELD_NAME] ? "true" : "false"}
        aria-label="ZIP code input"
        placeholder="Enter your ZIP code"
      />
    </div>
  );
};
