import { useState } from "react";
import styled from "styled-components";
import logo from "../images/logo_clear.png";
import { Link, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 90px;
  width: 100vw;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const MobileMenuContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 90px;
    left: 0;
    width: 100vw;
    background-color: white;
    padding: 10px;
    z-index: 1001;
  }
`;

const Logo = styled.img`
  height: 80px;
  margin-left: 20px;
  transition: transform 0.3s ease; /* Smooth scaling transition */
  
  &:hover {
    cursor: pointer;
    transform: scale(1.15); /* Scale the logo on hover */
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;

  // Hide the nav links on mobile screens
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  color: #111d57;
  font-size: 18px;
  font-weight: bold;
  font-family: "Source Sans Pro";
  line-height: 3;
  margin-right: 100px;
  margin-left: -65px;

  &:hover {
    color: #64d2d4;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    margin-right: 15px;
    margin-left: 15px;
  }
`;

const HamburgerIcon = styled(FiMenu)`
  display: none;

  @media (max-width: 768px) {
    display: block; // Only show on mobile screens
    cursor: pointer;
    margin-right: 50px;
  }
`;

const AccountNavbar = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogoClick = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <NavbarContainer>
      <GlobalStyles /> {/* Ensure this is used here */}
      <Link to="/" onClick={handleLogoClick}>
        <Logo src={logo} alt="EcoTrove Logo" />
      </Link>
      <NavLinks>
        <NavLink href="/user-dashboard">Dashboard</NavLink>
        <NavLink href="/manage-account">Manage Account</NavLink>
        <NavLink href="/save-more">Save More</NavLink>
        <NavLink href="/faqs">FAQs</NavLink>
      </NavLinks>
      <HamburgerIcon size={24} onClick={toggleMobileMenu} />
      {isMobileMenuOpen && (
        <MobileMenuContainer>
          <NavLink onClick={closeMobileMenu} href="user-dashboard">
            Dashboard
          </NavLink>
          <NavLink href="/manage-account">Manage Account</NavLink>
          <NavLink href="/save-more">Save More</NavLink>
          <NavLink onClick={closeMobileMenu} href="faqs">
            FAQs
          </NavLink>
        </MobileMenuContainer>
      )}
    </NavbarContainer>
  );
};

export default AccountNavbar;