import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HowItWorks from "../components/HowItWorks";
import BottomLandingSectionGetQuote from "../components/BottomLandingSectionGetQuote";
import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
`;

const HowItWorksPage = () => {
  return (
    <>
    <GlobalStyles /> {/* Include global styles */}
    <Helmet>
      <title>EcoTrove | How It Works</title>
      <meta name="description" content="Securely connect your utility account with EcoTrove to get a personalized savings estimate to reaplce your utility bills." />
      <meta name="keywords" content="utility account, PG&E account, get a personalized quote, replace your utility bills, save on your utility bills, clean energy, utility bill savings" />
    </Helmet> 

    <main>
    <PageContainer>
      <Navbar />
      <HowItWorks />
      <PlasmicRootProvider loader={PLASMIC}>
          <PlasmicComponent component="NewHomePageExplainer" />
        </PlasmicRootProvider>
      <BottomLandingSectionGetQuote />
      <Footer></Footer>
    </PageContainer>
    </main>
    </>
  );
};

export default HowItWorksPage;
