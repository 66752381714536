import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import GlobalStyles from '../GlobalStyles'; // Import the global styles


const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  font-family: "Source Sans Pro";
  line-height: 1.6;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

const SubTitle = styled.h2`
  margin-top: 30px;
`;

const Paragraph = styled.p`
  margin: 10px 0;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const TableOfContents = styled.div`
  margin: 20px 0;
  padding: 20px;
  background: #f0f8ff;
  border: 1px solid #007acc;
  border-radius: 8px;
`;

const TableOfContentsTitle = styled.h3`
  margin-bottom: 10px;
`;

const TOCLink = styled.a`
  display: block;
  margin-bottom: 10px;
  color: #007acc;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
  margin: 20px 0;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background: #f0f8ff;
  border: 1px solid #007acc;
  padding: 10px;
  text-align: left;
`;

const TableCell = styled.td`
  border: 1px solid #007acc;
  padding: 10px;
`;

const PrintLink = styled.a`
  color: #007acc;
  text-decoration: underline;
  cursor: pointer;
`;

const PrivacyPolicy = () => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
     <GlobalStyles /> {/* Include global styles */}
    <Navbar />
    <Container>
      <Title>EcoTrove's Privacy Policy</Title>
      <Paragraph><strong>Effective date: October 1st, 2024</strong></Paragraph>

      <Paragraph>
        At EcoTrove, we take your privacy seriously. Please read this Privacy
        Policy to learn how we treat your personal data. By using or accessing
        our Services in any manner, you acknowledge that you accept the
        practices and policies outlined below, and you hereby consent that we
        will collect, use and disclose your information as described in this
        Privacy Policy.
      </Paragraph>

      <Paragraph>
        Remember that your use of EcoTrove's Services is at all times subject to
        our{" "}
        <a href="https://ecotrove.com/terms-of-service">Terms of Service</a>,
        which incorporates this Privacy Policy. Any terms we use in this Policy
        without defining them have the definitions given to them in the Terms of
        Use.
      </Paragraph>

      <Paragraph>
        You may print a copy of this Privacy Policy by{" "}
        <PrintLink onClick={handlePrint}>clicking here</PrintLink>.
      </Paragraph>

      <Paragraph>
        As we continually work to improve our Services, we may need to change
        this Privacy Policy from time to time. We will alert you of material
        changes by placing a notice on the EcoTrove website, by sending you an
        email and/or by some other means. Please note that if you’ve opted not
        to receive legal notice emails from us (or you haven’t provided us with
        your email address), those legal notices will still govern your use of
        the Services, and you are still responsible for reading and
        understanding them. If you use the Services after any changes to the
        Privacy Policy have been posted, that means you agree to all of the
        changes.
      </Paragraph>

      <TableOfContents>
        <TableOfContentsTitle>Privacy Policy Table of Contents</TableOfContentsTitle>
        <TOCLink href="#what-this-privacy-policy-covers">What this Privacy Policy Covers</TOCLink>
        <TOCLink href="#personal-data">Personal Data</TOCLink>
        <TOCLink href="#categories-of-personal-data-we-collect">
          Categories of Personal Data We Collect
        </TOCLink>
        <TOCLink href="#our-commercial-or-business-purposes-for-collecting-personal-data">
          Our Commercial or Business Purposes for Collecting Personal Data
        </TOCLink>
        <TOCLink href="#other-permitted-purposes-for-processing-personal-data">
          Other Permitted Purposes for Processing Personal Data
        </TOCLink>
        <TOCLink href="#categories-of-sources-of-personal-data">
          Categories of Sources of Personal Data
        </TOCLink>
        <TOCLink href="#how-we-disclose-your-personal-data">How We Disclose Your Personal Data</TOCLink>
        <TOCLink href="#tracking-tools-advertising-and-opt-out">
          Tracking Tools, Advertising and Opt-Out
        </TOCLink>
        <TOCLink href="#data-security">Data Security</TOCLink>
        <TOCLink href="#personal-data-of-children">Personal Data of Children</TOCLink>
        <TOCLink href="#other-state-law-privacy-rights">Other State Law Privacy Rights</TOCLink>
        <TOCLink href="#contact-information">Contact Information</TOCLink>
      </TableOfContents>

      <Section id="what-this-privacy-policy-covers">
        <SubTitle>What this Privacy Policy Covers</SubTitle>
        <Paragraph>
          This Privacy Policy covers how we treat Personal Data that we gather
          when you access or use our Services. “Personal Data” means any
          information that identifies or relates to a particular individual and
          also includes information referred to as “personally identifiable
          information” or “personal information” under applicable data privacy
          laws, rules or regulations. This Privacy Policy does not cover the
          practices of companies we don’t own or control or people we don’t
          manage.
        </Paragraph>
      </Section>

      <Section id="personal-data">
        <SubTitle>Personal Data</SubTitle>
      </Section>

      <Section id="categories-of-personal-data-we-collect">
        <SubTitle>Categories of Personal Data We Collect</SubTitle>
        <Paragraph>
          This chart details the categories of Personal Data that we collect and
          have collected over the past 12 months:
        </Paragraph>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <TableHeader>Category of Personal Data</TableHeader>
                <TableHeader>Business or Commercial Purpose(s) for Collection</TableHeader>
                <TableHeader>Categories of Third Parties With Whom We Disclose this Personal Data</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell>Profile or Contact Data</TableCell>
                <TableCell>
                  Providing, Customizing and Improving the Services; Marketing
                  the Services; Corresponding with You
                </TableCell>
                <TableCell>Service Providers, Parties you Authorize, Access or Authenticate</TableCell>
              </tr>
              <tr>
                <TableCell>Payment Data</TableCell>
                <TableCell>
                  Providing, Customizing and Improving the Services; Marketing
                  the Services; Corresponding with You
                </TableCell>
                <TableCell>Service Providers, Parties you Authorize, Access or Authenticate</TableCell>
              </tr>
              <tr>
                <TableCell>Commercial Data</TableCell>
                <TableCell>
                  Providing, Customizing and Improving the Services; Marketing
                  the Services; Corresponding with You
                </TableCell>
                <TableCell>Service Providers, Parties you Authorize, Access or Authenticate</TableCell>
              </tr>
              <tr>
                <TableCell>Web Analytics</TableCell>
                <TableCell>
                  Providing, Customizing and Improving the Services; Marketing
                  the Services; Corresponding with You
                </TableCell>
                <TableCell>Service Providers, Parties you Authorize, Access or Authenticate</TableCell>
              </tr>
              <tr>
                <TableCell>Consumer Demographic Data</TableCell>
                <TableCell>
                  Providing, Customizing and Improving the Services; Marketing
                  the Services; Corresponding with You
                </TableCell>
                <TableCell>Service Providers, Parties you Authorize, Access or Authenticate</TableCell>
              </tr>
              <tr>
                <TableCell>Health Data</TableCell>
                <TableCell>
                  Providing, Customizing and Improving the Services; Marketing
                  the Services; Corresponding with You
                </TableCell>
                <TableCell>Service Providers, Parties you Authorize, Access or Authenticate</TableCell>
              </tr>
              <tr>
                <TableCell>
                  Other Identifying Information that You Voluntarily Choose to
                  Provide, including any data related to your Energy Account.
                </TableCell>
                <TableCell>
                  Providing, Customizing and Improving the Services; Marketing
                  the Services; Corresponding with You
                </TableCell>
                <TableCell>Service Providers, Parties you Authorize, Access or Authenticate</TableCell>
              </tr>
            </tbody>
          </Table>
        </TableContainer>
      </Section>

      {/* Our Commercial or Business Purposes for Collecting Personal Data */}
      <Section id="our-commercial-or-business-purposes-for-collecting-personal-data">
        <SubTitle>Our Commercial or Business Purposes for Collecting Personal Data</SubTitle>
        <List>
          <ListItem>Providing, Customizing and Improving the Services</ListItem>
          <List>
            <ListItem>Creating and managing your account or other user profiles.</ListItem>
            <ListItem>Processing orders or other transactions; billing.</ListItem>
            <ListItem>Providing you with the products, services or information you request.</ListItem>
            <ListItem>Meeting or fulfilling the reason you provided the information to us.</ListItem>
            <ListItem>Providing support and assistance for the Services.</ListItem>
            <ListItem>
              Improving the Services, including testing, research, internal
              analytics and product development.
            </ListItem>
            <ListItem>
              Personalizing the Services, website content and communications
              based on your preferences.
            </ListItem>
            <ListItem>Doing fraud protection, security and debugging.</ListItem>
          </List>
          <ListItem>Marketing the Services</ListItem>
          <List>
            <ListItem>Marketing and selling the Services.</ListItem>
            <ListItem>
              Showing users advertisements, including interest-based or online
              behavioral advertising.
            </ListItem>
          </List>
          <ListItem>Corresponding with You</ListItem>
          <List>
            <ListItem>
              Responding to correspondence that we receive from you, contacting
              you when necessary or requested, and sending you information about
              EcoTrove or the Services.
            </ListItem>
            <ListItem>Sending emails and other communications according to your preferences.</ListItem>
          </List>
        </List>
      </Section>

     {/* Other Permitted Purposes for Processing Personal Data */}
     <Section id="other-permitted-purposes-for-processing-personal-data">
        <SubTitle>Other Permitted Purposes for Processing Personal Data</SubTitle>
        <Paragraph>
          In addition, each of the above referenced categories of Personal Data
          may be collected, used, and disclosed with the government, including
          law enforcement, or other parties to meet certain legal requirements
          and enforcing legal terms including: fulfilling our legal obligations
          under applicable law, regulation, court order or other legal process,
          such as preventing, detecting and investigating security incidents and
          potentially illegal or prohibited activities; protecting the rights,
          property or safety of you, EcoTrove or another party; enforcing any
          agreements with you; responding to claims that any posting or other
          content violates third-party rights; and resolving disputes.
        </Paragraph>
        <Paragraph>
          We will not collect additional categories of Personal Data or use the
          Personal Data we collected for materially different, unrelated or
          incompatible purposes without providing you notice or obtaining your
          consent.
        </Paragraph>
      </Section>

      {/* Categories of Sources of Personal Data */}
      <Section id="categories-of-sources-of-personal-data">
        <SubTitle>Categories of Sources of Personal Data</SubTitle>
        <Paragraph>We collect Personal Data about you from the following categories of sources:</Paragraph>
        <List>
          <ListItem>You</ListItem>
          <List>
            <ListItem>When you provide such information directly to us.</ListItem>
            <List>
              <ListItem>When you create an account or use our interactive tools and Services.</ListItem>
              <ListItem>
                When you voluntarily provide information in free-form text boxes
                through the Services or through responses to surveys or
                questionnaires.
              </ListItem>
              <ListItem>When you send us an email or otherwise contact us.</ListItem>
            </List>
            <ListItem>When you use the Services and such information is collected automatically.</ListItem>
            <List>
              <ListItem>Through Cookies (defined in the “Tracking Tools, Advertising and Opt-Out” section below).</ListItem>
              <ListItem>
                If you download and install certain applications and software we
                make available, we may receive and collect information
                transmitted from your computing device for the purpose of
                providing you the relevant Services, such as information
                regarding when you are logged on and available to receive
                updates or alert notices.
              </ListItem>
            </List>
          </List>
        </List>
      </Section>

      {/* How We Disclose Your Personal Data */}
      <Section id="how-we-disclose-your-personal-data">
        <SubTitle>How We Disclose Your Personal Data</SubTitle>
        <Paragraph>
          We disclose your Personal Data to the categories of service providers
          and other parties listed in this section. Depending on state laws that
          may be applicable to you, some of these disclosures may constitute a
          “sale” of your Personal Data. For more information, please refer to
          the state-specific sections below.
        </Paragraph>
        <List>
          <ListItem>Service Providers. These parties help us provide the Services or perform business functions on our behalf. They include:</ListItem>
          <List>
            <ListItem>Hosting, technology and communication providers.</ListItem>
            <ListItem>Analytics providers for web traffic or usage of the site.</ListItem>
            <ListItem>Security and fraud prevention consultants.</ListItem>
            <ListItem>Support and customer service vendors.</ListItem>
            <ListItem>Product fulfillment and delivery providers.</ListItem>
            <ListItem>
              Payment processors.
              <Paragraph>
                Our payment processing partner Stripe, Inc. (“Stripe”) collects
                your voluntarily-provided payment card information necessary to
                process your payment.
              </Paragraph>
              <Paragraph>
                Please see Stripe’s terms of service and privacy policy for
                information on its use and storage of your Personal Data.
              </Paragraph>
            </ListItem>
          </List>
          <ListItem>Parties You Authorize, Access or Authenticate.</ListItem>
          <List>
            <ListItem>Your Energy Provider.</ListItem>
          </List>
        </List>
      </Section>

      {/* Tracking Tools, Advertising and Opt-Out */}
      <Section id="tracking-tools-advertising-and-opt-out">
        <SubTitle>Tracking Tools, Advertising and Opt-Out</SubTitle>
        <Paragraph>
          The Services use cookies and similar technologies such as pixel tags,
          web beacons, clear GIFs and JavaScript (collectively, “Cookies”) to
          enable our servers to recognize your web browser, tell us how and when
          you visit and use our Services, analyze trends, learn about our user
          base and operate and improve our Services. Cookies are small pieces of
          data– usually text files – placed on your computer, tablet, phone or
          similar device when you use that device to access our Services. We may
          also supplement the information we collect from you with information
          received from third parties, including third parties that have placed
          their own Cookies on your device(s).
        </Paragraph>
        <Paragraph>
          Please note that because of our use of Cookies, the Services do not
          support “Do Not Track” requests sent from a browser at this time.
        </Paragraph>
        <Paragraph>We use the following types of Cookies:</Paragraph>
        <List>
          <ListItem>Essential Cookies</ListItem>
          <Paragraph>
            Essential Cookies are required for providing you with features or
            services that you have requested. For example, certain Cookies
            enable you to log into secure areas of our Services. Disabling these
            Cookies may make certain features and services unavailable.
          </Paragraph>
          <ListItem>Functional Cookies</ListItem>
          <Paragraph>
            Functional Cookies are used to record your choices and settings
            regarding our Services, maintain your preferences over time and
            recognize you when you return to our Services. These Cookies help us
            to personalize our content for you, greet you by name and remember
            your preferences (for example, your choice of language or region).
          </Paragraph>
          <ListItem>Performance/Analytical Cookies</ListItem>
          <Paragraph>
            Performance/Analytical Cookies allow us to understand how visitors
            use our Services. They do this by collecting information about the
            number of visitors to the Services, what pages visitors view on our
            Services and how long visitors are viewing pages on the Services.
            Performance/Analytical Cookies also help us measure the performance
            of our advertising campaigns in order to help us improve our
            campaigns and the Services’ content for those who engage with our
            advertising. For example, Google LLC (“Google”) uses cookies in
            connection with its Google Analytics services. Google’s ability to
            use and disclose information collected by Google Analytics about
            your visits to the Services is subject to the Google Analytics Terms
            of Use and the Google Privacy Policy. You have the option to opt-out
            of Google’s use of Cookies by visiting the Google advertising opt-out
            page at{" "}
            <a href="https://www.google.com/privacy_ads.html">www.google.com/privacy_ads.html</a>{" "}
            or the Google Analytics Opt-out Browser Add-on at{" "}
            <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
            </Paragraph>
        </List>
      </Section>
      <Paragraph>
          You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers have an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your device. If you do this, however, you may have to manually adjust some preferences every time you visit our website and some of the Services and functionalities may not work.
        </Paragraph>

      {/* Information about Interest-Based Advertisements */}
      <Section id="information-about-interest-based-advertisements">
        <SubTitle>Information about Interest-Based Advertisements</SubTitle>
        <Paragraph>
          We may serve advertisements, and also allow third-party ad networks, including third-party ad servers, ad agencies, ad technology vendors and research firms, to serve advertisements through the Services. These advertisements may be targeted to users who fit certain general profile categories or display certain preferences or behaviors (“Interest-Based Ads”). Information for Interest-Based Ads (including Personal Data) may be provided to us by you, or derived from the usage patterns of particular users on the Services and/or services of third parties. Such information may be gathered through tracking users’ activities across time and unaffiliated properties, including when you leave the Services. To accomplish this, we or our service providers may deliver Cookies, including a file (known as a “web beacon”) from an ad network to you through the Services. Web beacons allow ad networks to provide anonymized, aggregated auditing, research and reporting for us and for advertisers. Web beacons also enable ad networks to serve targeted advertisements to you when you visit other websites. Web beacons allow ad networks to view, edit or set their own Cookies on your browser, just as if you had requested a web page from their site.
        </Paragraph>
      </Section>

      {/* Data Security */}
      <Section id="data-security">
        <SubTitle>Data Security</SubTitle>
        <Paragraph>
          We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical, organizational and administrative security measures based on the type of Personal Data and how we are processing that data. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the internet or storing data is completely secure.
        </Paragraph>
      </Section>

      {/* Data Retention */}
      <Section id="data-retention">
        <SubTitle>Data Retention</SubTitle>
        <Paragraph>
          We retain Personal Data about you for as long as necessary to provide you with our Services or to perform our business or commercial purposes for collecting your Personal Data. When establishing a retention period for specific categories of data, we consider who we collected the data from, our need for the Personal Data, why we collected the Personal Data, and the sensitivity of the Personal Data. In some cases we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. We may further retain information in an anonymous or aggregated form where that information would not identify you personally.
        </Paragraph>
        <Paragraph>
          For example:
        </Paragraph>
        <List>
          <ListItem>We retain your profile information and credentials for as long as you have an account with us.</ListItem>
          <ListItem>We retain your payment data for as long as we need to process your purchase or subscription.</ListItem>
        </List>
      </Section>

      {/* Personal Data of Children */}
      <Section id="personal-data-of-children">
        <SubTitle>Personal Data of Children</SubTitle>
        <Paragraph>
          As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data from children under 13 years of age; if you are a child under the age of 13, please do not attempt to register for or otherwise use the Services or send us any Personal Data. If we learn we have collected Personal Data from a child under 13 years of age, we will delete that information as quickly as possible. If you believe that a child under 13 years of age may have provided Personal Data to us, please contact us at <a href="mailto:info@ecotrove.com">info@ecotrove.com</a>.
        </Paragraph>
      </Section>

      {/* Other State Law Privacy Rights */}
      <Section id="other-state-law-privacy-rights">
        <SubTitle>Other State Law Privacy Rights</SubTitle>
        <Paragraph>California Resident Rights</Paragraph>
        <Paragraph>
          Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties’ direct marketing purposes; in order to submit such a request, please contact us at <a href="mailto:info@ecotrove.com">info@ecotrove.com</a>.
        </Paragraph>
        <Paragraph>
          Your browser may offer you a “Do Not Track” option, which allows you to signal to operators of websites and web applications and services that you do not wish such operators to track certain of your online activities over time and across different websites. Our Services do not support Do Not Track requests at this time. To find out more about “Do Not Track,” you can visit <a href="https://www.allaboutdnt.com">www.allaboutdnt.com</a>.
        </Paragraph>
        <Paragraph>Nevada Resident Rights</Paragraph>
        <Paragraph>
          Please note that we do not currently sell your Personal Data as sales are defined in Nevada Revised Statutes Chapter 603A.
        </Paragraph>
      </Section>

      {/* Contact Information */}
      <Section id="contact-information">
        <SubTitle>Contact Information</SubTitle>
        <Paragraph>
          If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact us at:
        </Paragraph>
        <List>
          <ListItem>+1 415-237-1740</ListItem>
          <ListItem><a href="https://ecotrove.com">ecotrove.com</a></ListItem>
          <ListItem><a href="mailto:info@ecotrove.com">info@ecotrove.com</a></ListItem>
          <ListItem>2 Embarcadero Street, Floor 8, San Francisco, California 94111</ListItem>
        </List>
      </Section>
      </Container> 
      <Footer />
      </div>
  );
};

export default PrivacyPolicy;