import React, { useState } from "react";
import styled from "styled-components";
import upArrow from "../images/upchevron.png";
import downArrow from "../images/downchevron.png";
import mixpanel from "mixpanel-browser";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const Container = styled.section`
  display: flex;
  justify-content: center;
  min-height: 40vh;
`;

const TestimonialSection = styled.section`
  background: linear-gradient(45deg, #e6f7ff, #e6f7ff, #e2ffe3);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60vw;

  @media (max-width: 768px) {
    width: 100vw;
  }

  @media (max-width: 710px) {
    flex-direction: column;
  }
`;

const TitleContainer = styled.div`
  padding: 20px;
  margin-left: 30px;
  width: 50%;

  @media (max-width: 710px) {
    width: 80%;
    margin-left: 0px;
    padding: 0px;
  }
`;

const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 50%;

  @media (max-width: 710px) {
    width: 80%;
    margin-left: 0px;
    padding: 0px;
    margin-bottom: 40px;
  }
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 44px;
  color: #111d57;

  @media (max-width: 430px) {
    font-size: 32px;
  }
`;

const TestimonialText = styled.h3`
  font-size: 20px;
  color: #111d57;
  font-weight: normal;

  @media (max-width: 430px) {
    font-size: 18px;
  }
`;

const TestimonialTextContainter = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const Author = styled.h4`
  font-size: 20px;
  color: #111d57;
  font-weight: bold;
  margin-top: 12px;

  @media (max-width: 430px) {
    font-size: 18px;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-width: 20%;
  button {
    background: none;
    border: none;
    cursor: pointer;
    margin: 10px;
  }
`;

const ArrowImage = styled.img`
  cursor: pointer;
`;

const testimonials = [
  {
    quote:
      "I don't worry about my electricity bill anymore. I know it's always going to be the same amount every month.",
    author: "- Will, San Francisco (saved $204)",
  },
  {
    quote: "EcoTrove made it super easy to switch to renewable energy. I didn't have to do anything and just got a notification it was done.",
    author: "- Riley, Santa Cruz (saved $273)",
  },
  {
    quote:
      "EcoTrove's subcription has been cheaper for me 10 months in the past year. Paying slightly higher for 2 months was worth it for the predictability and saving money on the other 10 months.",
    author: "- Serena, East Palo Alto (saved $120)",
  },
];

const Testimonials: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
    mixpanel.track("Clicked next testimonial");
  };

  return (
    <section id="testimonials">
       <GlobalStyles /> {/* Include global styles */}
      <Container>
        <TestimonialSection>
          <TitleContainer>
            <Title>Clean energy savings trusted by Californians</Title>
          </TitleContainer>
          <TestimonialContainer>
            <TestimonialTextContainter>
              <TestimonialText>
                {testimonials[currentIndex].quote}
              </TestimonialText>
              <Author>{testimonials[currentIndex].author}</Author>
            </TestimonialTextContainter>
            <ArrowContainer>
              <ArrowImage src={upArrow} onClick={prevTestimonial} />
              <ArrowImage src={downArrow} onClick={nextTestimonial} />
            </ArrowContainer>
          </TestimonialContainer>
        </TestimonialSection>
      </Container>
    </section>
  );
};

export default Testimonials;
