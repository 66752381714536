import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BottomLandingSectionGetQuote from "../components/BottomLandingSectionGetQuote";
import { PlasmicRootProvider, PlasmicComponent } from '@plasmicapp/loader-react';
import { PLASMIC } from '../plasmic-init';
import { Helmet } from 'react-helmet';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
`;

const AllBlogsPage = () => {
  return (
    <>
    <Helmet>
      <title>EcoTrove | Blogs</title>
      <meta name="description" content="Learn how EcoTrove helps household and small businesses lower their energy bills and how EcoTrove operates." />
      <meta name="keywords" content="clean energy blogs, navigating PG&E, accessible clean energy, unlocking energy savings, tips to lower energy bills, energy bill budgeting, help with saving on utility bills" />
    </Helmet> 

    <main>
    <PageContainer>
      <Navbar />
        <PlasmicRootProvider loader={PLASMIC}>
            <PlasmicComponent component="All_Blogs" />
        </PlasmicRootProvider>
      <BottomLandingSectionGetQuote />
      <Footer></Footer>
    </PageContainer>
    </main>
  </>
  );
};

export default AllBlogsPage;
