import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import GlobalStyles from '../GlobalStyles'; // Import the global styles
// import { supabase } from "../supabaseClient";

const Container = styled.section`
  margin-top: -50px;
  padding-top: -50px;
  display: flex;
  justify-content: center;
  align-items: center; /* Center content vertically */
  flex-direction: column; /* Stack items vertically */
  min-height: 96vh; /* Subtract Navbar height from viewport height */
  background-color: #e6f7ff; /* Specified background color */
`;

const Title = styled.p`
  font-size: 32px;
  font-weight: bold;
  color: #111d57;
  line-height: 1.5;
  margin-bottom: 5px;
  padding-top: -50px;
  text-align: center; /* Center text on desktop */

  @media (max-width: 768px) {
    font-size: 28px;
    text-align: center; /* Ensure text is centered on mobile */
  }
`;

const Subtitle = styled.p`
  color: #666;
  font-size: 24px;
  line-height: 1.5;
  margin-top: 5px;
  padding-top: -50px;
  text-align: center; /* Center text on both desktop and mobile */
  
  @media (max-width: 768px) {
    font-size: 22px;
    text-align: center; /* Ensure text is centered on mobile */
  }
`;

const RequestEmailedQuote = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get("email");
  // if (process.env.REACT_APP_TESTING !== "true") {
  //   console.log("accepted quote");
  //   const supabasePromise = supabase
  //     .from("user-profiles")
  //     .update({
  //       accepted_quote: true,
  //     })
  //     .eq("email", email);
  //   console.log("done uploading quote to supabase");
  
  //   supabasePromise.then(({ error }) => {
  //     if (error) {
  //       console.error('Error:', error.message);
  //     } else {
  //       console.log('Successfully logged complete user to Supabase.');
  //     }
  //   });
  // }

  return (
    <div>
      <GlobalStyles /> {/* Include global styles */}
      <Navbar />
      <Container>
        <Title>Thank you for using EcoTrove!</Title> 
        <Subtitle>We will email {email} your quote within the next 24 hours</Subtitle>
      </Container>
      <Footer></Footer>
    </div>
  );
};

export default RequestEmailedQuote;
