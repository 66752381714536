import React from "react";
import styled from "styled-components";
import aboutUsBackground from '../images/about_us_background.jpeg';
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const Section = styled.section`
  display: flex;  
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 62vh;
  padding: 30px 30px;
  width: 100%; /* Make sure the section takes full width */
  background-image: url(${aboutUsBackground});
  background-size: cover; /* Ensure the background image covers the section */
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden; /* Prevent horizontal scrolling */
  
  @media (max-width: 768px) {
    height: 82vh;
    padding: 15px 20px;
  }
`;

const ContentContainer = styled.div`
  width: 70%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    gap: 5vh;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  align-items: center;
  text-align: center;
`;

const Heading = styled.h1`
  color: #111d57;
  font-size: 34px;
  margin-bottom: 20px;
  font-family: "Source Sans Pro";
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 28px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Paragraph = styled.h2`
  color: #111d57;
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 20px;
  font-family: "Source Sans Pro";
  line-height: 1.5;
  align-items: center;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 5 5px;
    width: 100%;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #284389;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: bold;
  width: 220px;
  height: 45px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #111d57;
  }
`;

const MailtoLink = styled.a`
  text-decoration: none;
  align-items: center;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const AboutUsTopSection = () => {
  return (
    <Section>
      <GlobalStyles /> {/* Include global styles */}
      <ContentContainer>
        <TextContainer>
          <Heading>Making Clean Energy Accessible</Heading>
          <Paragraph>
            Our mission is to make clean energy accessible and affordable. We believe you deserve to live in comfort without depending on fossil fuels or sacrificing your financial stability.
          </Paragraph>
          <Paragraph>
            We are building EcoTrove so that you can use the power you need, seamlessly transition to renewable energy, and pay the same price, every month, no matter what.
          </Paragraph>
          <MailtoLink href="mailto:info@ecotrove.com">
            <Button>Get in Touch</Button>
          </MailtoLink>
        </TextContainer>
      </ContentContainer>
    </Section>
  );
};

export default AboutUsTopSection;
