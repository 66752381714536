import { RDCRatingStars } from "./RDCRatingStars";

/**
 * Props to pass to a `RDCCustomerReviewCard` component.
 */
export interface RDCCustomerReviewCardProps {
  name: string;
  imgUrl: string;
  numStars: number;
  textContent: JSX.Element;
}

/**
 * Card component that displays a customer review.
 * @param props the props to render the component with.
 * @returns a React component.
 */
export const RDCCustomerReviewCard: React.FC<RDCCustomerReviewCardProps> = (props) => {
  const { name, imgUrl, numStars, textContent } = props;
  return (
    <div className="grid grid-cols-1 items-start gap-default w-72 lg:w-128 rounded-3xl bg-base-100 p-5">
      {/* Person & rating */}
      <div className="flex items-center gap-default">
        <img className="daisy-mask daisy-mask-circle w-8" src={imgUrl} alt="profile" />
        <div className="flex flex-col gap-1">
          <p>{name}</p>
          <RDCRatingStars rating={numStars} />
        </div>
      </div>
      {/* Review text */}
      <span className="text-neutral-content">{textContent}</span>
    </div>
  );
};
