import { Route, Routes, Navigate } from "react-router-dom";
import LandingPageResidential from "./pages/LandingPageResidential";
import LandingPageCommercial from "./pages/LandingPageCommercial";
import PgeOAuthStart from "./pages/PgeOAuthStart";
import SdgeOauthRedirect from "./pages/SdgeOauthRedirect";
import SceDataAvailability from "./pages/SceDataAvailability";
import SceOauthRedirect from "./pages/SceOauthRedirect";
import DataAvailable from "./pages/DataAvailable";
import SelectCustodian from "./pages/SelectCustodian";
import ScopeSelection from "./pages/ScopeSelection";
import AboutUs from "./pages/AboutUs";
import GetQuote from "./pages/GetQuote";
import HowItWorksPage from "./pages/HowItWorksPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import PgeOAuthRedirect from "./pages/PgeOAuthRedirect";
import SignatureSuccess from "./pages/SignatureSuccess";
import CustomizeQuote from "./pages/CustomizeQuote";
import CreateAccount from "./pages/CreateAccount";
import UpdatePassword from "./pages/UpdatePassword";
import ConfirmEmail from "./pages/ConfirmEmail";
import UserDashboard from "./pages/UserDashboard";
import UserQuestions from "./pages/UserQuestions";
import LoginPage from "./pages/LoginPage";
import PaymentSuccess from "./pages/PaymentSuccess";
import TermsAndConditions from "./pages/TermsAndConditions";
import AllBlogs from "./pages/AllBlogs";
import NewQuote from "./pages/NewQuote";
import ShowQuote from "./pages/ShowQuote";
import LoadDocusign from "./pages/LoadDocusign";
import RequestEmailedQuote from "./pages/RequestEmailedQuote";
import BusinessOrHousehold from "./pages/BusinessOrHousehold";
import SaveMore from "./pages/SaveMore";
import ManageAccount from "./pages/ManageAccount";
import mixpanel from "mixpanel-browser";
import { Test } from "./pages/Test";
import EmpoweringSmallBusinesses from "./pages/blog_empowering_small_businesses";
import SerenaSaved from "./pages/blog_how_serena_saved";
import NavigatingPGE from "./pages/blog_navigating_pge";
import ThanksForReauthorizing from "./pages/ThanksForReauthorizing";
import PowerofCleanEnergy from "./pages/blog_power_of_clean_energy";
import UnlockingEnergySavings from "./pages/blog_unlocking_energy_savings";
import BattlingSkyrokcetingUtilities from "./pages/blog_battling_skyrocketing_utility_costs";
import FAQs from "./pages/FAQs";
import { RDCLandingPage } from "./redesign/pages";
import { REDESIGN_ROUTE } from "./constants";

import "./App.css";

const App = () => {
  const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

  if (!mixpanelToken) {
    console.error("No token found");
  } else {
    mixpanel.init(mixpanelToken, {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });
  }

  return (
    <Routes>
      {/* Redesign Routes */}
      {/* IMPORTANT: this link is still navigable to from external traffic */}
      {/* Do not put anything here that could harm our system and/or expose data (eg. untested database calls) */}
      <Route path={`${REDESIGN_ROUTE}/`} element={<RDCLandingPage />} />

      {/* Existing Routes */}
      <Route path="/" element={<LandingPageResidential />} />
      <Route path="/residential" element={<LandingPageResidential />} />
      <Route path="/commercial" element={<LandingPageCommercial />} />
      <Route path="/pge-oauth-start" element={<PgeOAuthStart />} />
      <Route path="/how-it-works" element={<HowItWorksPage />} />
      <Route path="/our-team" element={<AboutUs />} />
      <Route path="/get-quote" element={<GetQuote />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/faqs" element={<FAQs />} />
      <Route path="/pge-oauth-redirect" element={<PgeOAuthRedirect />}></Route>
      <Route path="/sdge-oauth-redirect" element={<SdgeOauthRedirect />} />
      <Route path="/sce-oauth-redirect" element={<SceOauthRedirect />} />
      <Route path="/sce-data-availability" element={<SceDataAvailability />} />
      <Route path="/data-available" element={<DataAvailable />} />
      <Route path="/scope-selection" element={<ScopeSelection />} />
      <Route path="/select-custodian" element={<SelectCustodian />} />
      <Route path="/new-quote" element={<NewQuote />} />
      <Route path="/create-account" element={<CreateAccount />} />
      <Route path="/update-password" element={<UpdatePassword />} />
      <Route path="/confirm-email" element={<ConfirmEmail />} />
      <Route path="/login-page" element={<LoginPage />} />
      <Route path="/user-dashboard" element={<UserDashboard />} />
      <Route path="/user-questions" element={<UserQuestions />} />
      <Route path="/signature-success" element={<SignatureSuccess />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/show-quote" element={<ShowQuote />} />
      <Route path="/load-docusign" element={<LoadDocusign />} />
      <Route path="/request-emailed-quote" element={<RequestEmailedQuote />} />
      <Route path="/thanks-for-reauthorizing" element={<ThanksForReauthorizing />} />
      <Route path="/business-or-household" element={<BusinessOrHousehold />} />
      <Route path="/customize-quote" element={<CustomizeQuote />} />
      <Route path="/all-blogs" element={<AllBlogs />} />
      <Route path="/blog-empowering-small-businesses" element={<EmpoweringSmallBusinesses />} />
      <Route path="/blog-how-serena-saved" element={<SerenaSaved />} />
      <Route path="/blog-navigating-pge" element={<NavigatingPGE />} />
      <Route path="/blog-power-of-clean-energy" element={<PowerofCleanEnergy />} />
      <Route path="/blog-unlocking-energy-savings" element={<UnlockingEnergySavings />} />
      <Route path="/blog-battling-skyrocketing-utility-costs" element={<BattlingSkyrokcetingUtilities />} />
      <Route path="/testing-ecotrove-from-plasmic" element={<Test />} />
      <Route path="/ecotrove_fixed_price_utility_bill" element={<Navigate to="/" replace />} />
      <Route path="/energy_subscription_quote" element={<Navigate to="/" replace />} />
      <Route path="/manage-account" element={<ManageAccount />} />
      <Route path="/save-more" element={<SaveMore />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default App;
