import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import styled from "styled-components";
import Navbar from "../components/Navbar";
import EnterZipCode from "../components/EnterZipCode";
import EnterEmail from "../components/EnterEmail";
import ThankYou from "../components/ThankYou";
import ConnectPGE from "../components/ConnectPGE";
import HowItWorks from "../components/HowItWorks";
import Testimontials from "../components/Testimonials";
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet';
import UserBehavior from "../components/UserBehavior";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Spacer = styled.section`
  height: 150px;
`;

const GetQuote = () => {
  const location = useLocation();
  const [stage, setStage] = useState('zipcode');


  // Use useEffect to handle the scroll when the component mounts
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []); // The empty dependency array ensures this runs only once when the component mounts

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const initialStage = params.get('initialStage');
    if (initialStage) {
      setStage(initialStage);
    }
  }, [location]);

  const advanceStage = (nextStage: string) => setStage(nextStage);
  return (
    <>
    <GlobalStyles /> {/* Include global styles */}
    <Helmet>
      <title>EcoTrove | How It Works</title>
      <meta name="description" content="Get a personalized quote to replace your utility bills with cheaper, predictable, clean energy." />
      <meta name="keywords" content="utility account, PG&E account, replace your utility bills, save on your utility bills, fixed-price energy, clean energy subscription, lower energy bills, EcoTrove, green power" />
   
      <UserBehavior />
   </Helmet> 

    <main>
    <Container>
      <Navbar />
      {stage === "connectPGE" && <ConnectPGE advanceStage={advanceStage} />}
      {stage === "zipcode" && <EnterZipCode advanceStage={advanceStage} />}
      {stage === "waitlistEmail" && <EnterEmail advanceStage={advanceStage} />}
      {stage === "waitlistThankYou" && <ThankYou />}
      <HowItWorks></HowItWorks>
      <Testimontials></Testimontials>
      <Spacer />
      <Footer />
    </Container>
    </main>
  </>
  );
};

export default GetQuote;
