/** Props to pass to a `RDCRatingStars` component. */
interface RDCRatingStarsProps {
  rating: number;
}

/**
 * Component that shows stars based on a passed in rating.
 * @param props the props to render the component with
 * @returns a React component.
 */
export const RDCRatingStars: React.FC<RDCRatingStarsProps> = (props) => {
  const { rating } = props;
  const ratingInt = Math.floor(rating);
  const hasHalf = rating % 1 > 0;

  // Create an array with booleans indicating whether the star should be a half
  const stars: boolean[] = Array(ratingInt).fill(false);
  if (hasHalf) stars.push(true);

  return (
    <ul className="flex h-5 gap-x-0.5">
      {stars.map((isHalf, i) => (
        <div
          className={`daisy-mask daisy-mask-star-2 bg-warning ${isHalf ? "daisy-mask-half-1 w-2.5" : "w-5"}`}
          key={i}
          aria-label={isHalf ? "half star icon" : "star icon"}
        />
      ))}
    </ul>
  );
};
