import styled, { keyframes } from "styled-components";
import howitworksinfo from "../images/how_it_works.jpeg";
import one_number from "../images/1_number.png";
import two_number from "../images/2_number.png";
import three_number from "../images/3_number.png";
import GlobalStyles from '../GlobalStyles'; // Import the global styles

// Keyframe animations
const enterFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const enterFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px 100px 0px;
  width: 100vw;
  background-color: #e6f7ff;

  @media (max-width: 768px) {
    margin: 30px 0px;
    width: 100vw;
  }
`;

const Title = styled.h1`
  color: #111d57;
  font-size: 48px;
  font-weight: bold;
  font-family: "Forum";
  text-align: center;
  margin: 0 0 40px 0;

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 35px;
  width: 90%;

  @media (max-width: 1600px) {
    gap: 30px;
    width: 90%;
  }

  @media (max-width: 1300px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 80%;
  }
`;

const ImageContainer = styled.div`
  text-align: center;
  margin-bottom: 10px;
  opacity: 0;
  animation: ${enterFromRight} 1.25s ease forwards;
  animation-delay: 5s; /* Adjusted for the new duration (3 * 1s + 3 * 1.25s + 0.5s) */
`;

const Explainer = styled.img`
  max-width: 550px;
  height: auto;

  @media (max-width: 768px) {
    max-width: 85vw;
  }
`;

const TextContainer = styled.div``;

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
  opacity: 0;
  animation: ${enterFromLeft} 1.25s ease forwards;
`;

const StepNumber = styled.img`
  margin-right: 12px;
  margin-top: -2px;
`;

const StepInfo = styled.h3`
  display: flex;
  font-weight: normal;
  flex-direction: column;
  font-family: "Forum";
`;

const StepTitle = styled.h2`
  font-size: 30px;
  font-family: "Forum";
  color: #111d57;
  font-weight: bold;
  margin-top: -16px;

  @media (max-width: 1600px) {
    font-size: 22px;
  }
`;

const StepDescription = styled.p`
  font-size: 18px;
  font-weight: normal;
  margin-top: -10px;
  color: #333;
  font-family: "Forum";
`;

interface StepComponentProps {
  numberImg: string;
  title: string;
  description: string;
  delay: string;
}

const StepComponent: React.FC<StepComponentProps> = ({
  numberImg,
  title,
  description,
  delay,
}) => (
  <Step style={{ animationDelay: delay }}>
    <StepNumber src={numberImg} alt="Step number" />
    <StepInfo>
      <StepTitle>{title}</StepTitle>
      <StepDescription>{description}</StepDescription>
    </StepInfo>
  </Step>
);

const HowItWorksLanding = () => {
  return (
    <section id="how-it-works">
      <GlobalStyles /> {/* Include global styles */}
      <Section>
        <Title>How EcoTrove works</Title>
        <ContentContainer>
          <TextContainer>
            <StepComponent
              numberImg={one_number}
              title="Link your utility account"
              description="Share your utility data with us so that we can identify green power rates in your area, applicable government incentives and rate plan savings opportunities. Your data is only used during analysis and deleted afterwards."
              delay="1s"
            />
            <StepComponent
              numberImg={two_number}
              title="Get a personalized monthly quote"
              description="Get a fixed-price monthly quote to use electricity and (if applicable) natural gas, at up to 10% cheaper rates than your current utility bills. EcoTrove only gets paid for savings we secure beyond your guaranteed quote. Your fixed price is guaranteed for 12 months, or until your year-over-year power usage grows by more than 15%."
              delay="2.25s"
            />
            <StepComponent
              numberImg={three_number}
              title="Optimize your utility bill with EcoTrove"
              description="Finalize your subscription to replace your monthly utility bill with EcoTrove's flat rates. EcoTrove works as your energy agent to pay your utility bills, find savings on your behalf, and automatically sources your electricity from a local renewable energy generator. You stop paying your utility and can cancel anytime if you're not satisfied."
              delay="3.5s"
            />
          </TextContainer>
          <ImageContainer>
            <Explainer src={howitworksinfo} alt="Eco-friendly illustration" />
          </ImageContainer>
        </ContentContainer>
      </Section>
    </section>
  );
};

export default HowItWorksLanding;


