import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import GlobalStyles from '../GlobalStyles'; // Import the global styles
// import { supabase } from "../supabaseClient";

const Container = styled.section`
  margin-top: -50px;
  padding-top: -50px;
  display: flex;
  justify-content: center;
  align-items: center; /* Center content vertically */
  flex-direction: column; /* Stack items vertically */
  min-height: 96vh; /* Subtract Navbar height from viewport height */
  background-color: #e6f7ff; /* Specified background color */
`;

const Title = styled.p`
  font-size: 32px;
  font-weight: bold;
  color: #111d57;
  line-height: 1.5;
  margin-bottom: 5px;
  padding-top: -50px;
  text-align: center; /* Center text on desktop */

  @media (max-width: 768px) {
    font-size: 28px;
    text-align: center; /* Ensure text is centered on mobile */
  }
`;

const Subtitle = styled.p`
  color: #666;
  font-size: 24px;
  line-height: 1.5;
  margin-top: 5px;
  padding-top: -50px;
  text-align: center; /* Center text on both desktop and mobile */
  
  @media (max-width: 768px) {
    font-size: 22px;
    text-align: center; /* Ensure text is centered on mobile */
  }
`;

const ThanksForReauthorizing = () => {

  return (
    <div>
      <GlobalStyles /> {/* Include global styles */}
      <Navbar />
      <Container>
        <Title>Thank you for re-authorizing your PG&E account with EcoTrove!</Title> 
        <Subtitle>Your bills are on us.</Subtitle>
      </Container>
      <Footer></Footer>
    </div>
  );
};

export default ThanksForReauthorizing;
